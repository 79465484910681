import { Map, Record } from 'immutable';

export interface PreAdmissionState extends Map<string, any> {
  preAdmissionCreatinging: boolean;
  preAdmissionCreated: boolean;
  preAdmissionCreationFailed: boolean;
  preAdmissionNo: boolean;
  preAdmissionList: any;
  preAdmissionLoading: boolean;
  preAdmissionLoaded: boolean;
  preAdmissionFailed: boolean;
  preAdmissionCount: any;

  statusChangeLoading: boolean;
  statusChangeLoaded: boolean;
  statusChange: any;
  generateLoading: boolean; 
  preAdmissionDetail: any;

  deletePreAdmission: any;
  deletePreAdmissionLoading: boolean;
  deletePreAdmissionLoaded: boolean;
  selectedStudents: Array<any>;
  sectionAllotLoaded: boolean;
  sectionAllotResult: any;
  sectionAllotLoading: boolean;

  importAdmissionLoaded: boolean;
  importAdmissionResult: any;
  importAdmissionLoading: boolean;

      downloadStudentTemplate: any;

    downloadStudentTemplateLoading: boolean;

    GenerateCashFreeOrder: any;
    GenerateCashFreeOrderLoading: boolean;

    groupConfigureDetails: any;


    getS3PreSignedUrl: any;
    getS3PreSignedUrlLoading: boolean;
    getS3PreSignedUrlLoaded: boolean;
    getS3PreSignedUrlFailed: boolean;
    

}

export const preAdmissionRecord = Record({
  preAdmissionCreatinging: false,
  preAdmissionCreated: false,
  preAdmissionCreationFailed: false,
  preAdmissionNo: '',

  preAdmissionLoading: false,
  preAdmissionLoaded: false,
  preAdmissionFailed: false,
  preAdmissionList: [],
  preAdmissionCount: 0,
  statusChangeLoading: false,
  statusChangeLoaded: false,
  statusChange: {},
  generateLoading: false,
  preAdmissionDetail: {},

  deletePreAdmission: null,
  deletePreAdmissionLoading: false,
  deletePreAdmissionLoaded: false,
  selectedStudents: [],

  sectionAllotLoaded: false,
  sectionAllotResult: null,
  sectionAllotLoading: false,

  importAdmissionLoaded: false,
  importAdmissionResult: null,
  importAdmissionLoading: false,
      downloadStudentTemplate: [],

    downloadStudentTemplateLoading: false,

    GenerateCashFreeOrder: {},
    GenerateCashFreeOrderLoading: false,
    groupConfigureDetails: [],


    getS3PreSignedUrl: [],
    getS3PreSignedUrlLoading: false,
    getS3PreSignedUrlLoaded: false,
    getS3PreSignedUrlFailed: false,

    
});
