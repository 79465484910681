import { PreadmissionListState, PreadmissionListStateRecord } from './preadmissionlist.state';
import * as actions from '../actions/preadmissionlist.action';

export const initialState: PreadmissionListState = (new PreadmissionListStateRecord() as unknown) as PreadmissionListState;

export function reducer(
  state = initialState,
  { type, payload }: any
): PreadmissionListState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * Login
     */
    case actions.ActionTypes.PREADMISSION_LIST:
      return Object.assign({}, state, {
        preadmissionlistLoading: true,
        preadmissionlistLoaded: false,
        preadmissionlistFailed: false,
      });
    case actions.ActionTypes.PREADMISSION_LIST_SUCCESS:
      return Object.assign({}, state, {
        preadmissionlist: payload.data,
        preadmissionlistLoading: false,
        preadmissionlistLoaded: true,
        preadmissionlistFailed: false,
      });
    case actions.ActionTypes.PREADMISSION_LIST_FAIL:
      return Object.assign({}, state, {
        preadmissionlistLoading: false,
        preadmissionlistLoaded: false,
        preadmissionlistFailed: true,
      });

      case actions.ActionTypes.RENEWAL_DETAILS_LIST:
      return Object.assign({}, state, {
        renewalDetailsLoading: true,
        renewalDetailsLoaded: false,
        renewalDetailsFailed: false,
      });
    case actions.ActionTypes.RENEWAL_DETAILS_LIST_SUCCESS:
      return Object.assign({}, state, {
        renewalDetails: payload.data,
        renewalDetailsLoading: false,
        renewalDetailsLoaded: true,
        renewalDetailsFailed: false,
      });
    case actions.ActionTypes.RENEWAL_DETAILS_LIST_FAIL:
      return Object.assign({}, state, {
        renewalDetailsLoading: false,
        renewalDetailsLoaded: false,
        renewalDetailsFailed: true,
      });
      case actions.ActionTypes.ADMISSION_PAYMENT_DETAILS:
        return Object.assign({}, state, {
          admissionPaymentDetails: {},
          admissionPaymentDetailsLoading: true
        });
      case actions.ActionTypes.ADMISSION_PAYMENT_DETAILS_SUCCESS:
        return Object.assign({}, state, {
          admissionPaymentDetails: payload,
          admissionPaymentDetailsLoading: false
        });
      case actions.ActionTypes.ADMISSION_PAYMENT_DETAILS_FAIL:
        return Object.assign({}, state, {
          admissionPaymentDetails: {},
          admissionPaymentDetailsLoading: false
        });

    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const preadmissionlist = (state: PreadmissionListState) => state.preadmissionlist;
export const preadmissionlistLoading = (state: PreadmissionListState) =>state.preadmissionlistLoading;
export const preadmissionlistLoaded = (state: PreadmissionListState) => state.preadmissionlistLoaded;
export const preadmissionlistFailed = (state: PreadmissionListState) => state.preadmissionlistFailed;

export const renewalDetails = (state: PreadmissionListState) => state.renewalDetails;
export const renewalDetailsLoading = (state: PreadmissionListState) => state.renewalDetailsLoading;
export const renewalDetailsLoaded = (state: PreadmissionListState) => state.renewalDetailsLoaded;
export const renewalDetailsFailed = (state: PreadmissionListState) => state.renewalDetailsFailed;
export const admissionPaymentDetails = (state: PreadmissionListState) => state.admissionPaymentDetails;
export const admissionPaymentDetailsLoading = (state: PreadmissionListState) => state.admissionPaymentDetailsLoading;
