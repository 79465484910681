import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as actions from '../actions/preadmissionlist.action';
import { preadmissionlistService } from '../preadmissionlist.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import printJS from 'print-js';


/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application. StateUpdates is an observable of the latest state and
 * dispatched action. The `toPayload` helper function returns just
 * the payload of the currently dispatched action, useful in
 * instances where the current state is not necessary.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class preadmissionlistEffect {
  /**
   * subject list effect
   */
  @Effect()
  preadmissionlist$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.PREADMISSION_LIST),
    map((action: actions.preadmissionlist) => action.payload),
    switchMap(state => {
      return this.api.preadmissionlist(state).pipe(
        tap(data => {          
      }),
        map(user => new actions.preadmissionlistSuccess(user)),
        catchError(error => of(new actions.preadmissionlistFail(error.error)))
      );
    })
  );

  @Effect()
  updateApplicationPaymentStatus$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.UPDATE_PAYMENT_STATUS),
    map((action: actions.updateApplicationPaymentStatus) => action.payload),
    switchMap(state => {
      return this.api.updateApplicationPaymentStatus(state).pipe(
        map(user => new actions.updateApplicationPaymentStatusSuccess(user)),
        catchError(error => of(new actions.updateApplicationPaymentStatusFail(error.error)))
      );
    })
  );

  @Effect()
  admissionPaymentDetails$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.ADMISSION_PAYMENT_DETAILS),
    map((action: actions.admissionPaymentDetails) => action.payload),
    switchMap(state => {
      return this.api.admissionPaymentDetails(state).pipe(
        map(user => new actions.admissionPaymentDetailsSuccess(user)),
        catchError(error =>
          of(new actions.admissionPaymentDetailsFail(error.error))
        )
      );
    })
  );
  // renewal details
  // @Effect()
  // renewalDetails$: Observable<Action> = this.action$.pipe(
  //   ofType(actions.ActionTypes.RENEWAL_DETAILS_LIST),
  //   map((action: actions.RenewalDetails) => action.payload),
  //   switchMap(state => {
  //     return this.api.getRenewalDetails(state).pipe(
  //       map(user => new actions.RenewalDetailsSuccess(user)),
  //       catchError(error => of(new actions.RenewalDetailsFail(error.error)))
  //     );
  //   })
  // );
   /**
   * change password effect
   */

  constructor(
    private action$: Actions,
    protected api: preadmissionlistService,
    public router: Router
  ) {}
}
