import { PreAdmissionState, preAdmissionRecord } from './preadmissionform.state';
import * as actions from '../actions/preadmissionform.action';

export const initialState: PreAdmissionState = (new preAdmissionRecord() as unknown) as PreAdmissionState;

export function reducer(
  state = initialState,
  { type, payload }: any
): PreAdmissionState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * create pre admission reducer case function
     */
    case actions.ActionTypes.CREATE_PRE_ADMISSION:
      return Object.assign({}, state, {
        preAdmissionCreatinging: true,
        preAdmissionCreated: false,
        preAdmissionCreationFailed: false
      });
    case actions.ActionTypes.CREATE_PRE_ADMISSION_SUCCESS:
      //nst Admsis = localStorage.setItem('ttt', payload.data.preAdmissionNo);
      return Object.assign({}, state, {
        preAdmissionCreatinging: false,
        preAdmissionCreated: payload.data,
        preAdmissionCreationFailed: false
      });
    case actions.ActionTypes.CREATE_PRE_ADMISSION_FAIL:
      return Object.assign({}, state, {
        preAdmissionCreatinging: false,
        preAdmissionCreated: true,
        preAdmissionCreationFailed: true
      });



    case actions.ActionTypes.GENERATE_CASHFREE_ORDER:
      return Object.assign({}, state, {
        GenerateCashFreeOrder: {},
        GenerateCashFreeOrderLoading: true
      });
    case actions.ActionTypes.GENERATE_CASHFREE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        GenerateCashFreeOrder: payload,
        GenerateCashFreeOrderLoading: false
      });
    case actions.ActionTypes.GENERATE_CASHFREE_ORDER_FAIL:
      return Object.assign({}, state, {
        GenerateCashFreeOrder: {},
        GenerateCashFreeOrderLoading: false
      });
    /**
     * create pre admission reducer case function
     */
    case actions.ActionTypes.UPDATE_PRE_ADMISSION_DETAIL:
      return Object.assign({}, state, {
        preAdmissionCreatinging: true,
        preAdmissionCreated: false,
        preAdmissionCreationFailed: false
      });
    case actions.ActionTypes.UPDATE_PRE_ADMISSION_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        preAdmissionCreatinging: false,
        preAdmissionCreated: true,
        preAdmissionCreationFailed: false
      });
    case actions.ActionTypes.UPDATE_PRE_ADMISSION_DETAIL_FAIL:
      return Object.assign({}, state, {
        preAdmissionCreatinging: false,
        preAdmissionCreated: true,
        preAdmissionCreationFailed: true
      });
    /**
   * pre admission list reducer case function
   */
    case actions.ActionTypes.GET_PRE_ADMISSION_LIST:
      if (payload.refresh) {
        return Object.assign({}, state, {
          preAdmissionList: [],
          preAdmissionLoading: true,
          preAdmissionLoaded: false,
          preAdmissionFailed: false
        });
      } else {
        return Object.assign({}, state, {
          preAdmissionLoading: false,
          preAdmissionLoaded: false,
          preAdmissionFailed: false
        });
      }
    case actions.ActionTypes.GET_PRE_ADMISSION_LIST_SUCCESS:
      return Object.assign({}, state, {
        preAdmissionList: payload.data,
        preAdmissionLoading: false,
        preAdmissionLoaded: true,
        preAdmissionFailed: false
      });
    case actions.ActionTypes.GET_PRE_ADMISSION_LIST_FAIL:
      return Object.assign({}, state, {
        preAdmissionLoading: false,
        preAdmissionLoaded: true,
        preAdmissionFailed: true
      });
    /**
  * pre admission count reducer case function
  */
    case actions.ActionTypes.GET_PRE_ADMISSION_COUNT:
      return Object.assign({}, state, {
      });
    case actions.ActionTypes.GET_PRE_ADMISSION_COUNT_SUCCESS:
      return Object.assign({}, state, {
        preAdmissionCount: payload.data,
      });
    case actions.ActionTypes.GET_PRE_ADMISSION_COUNT_FAIL:
      return Object.assign({}, state, {
      });
    /**
   * pre admission detail reducer case function
   */
    case actions.ActionTypes.GET_PRE_ADMISSION_DETAIL:
      return Object.assign({}, state, {
        preAdmissionDetail: null,
        preAdmissionNo: null
      });
    case actions.ActionTypes.GET_PRE_ADMISSION_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        preAdmissionDetail: payload.data,
      });
    case actions.ActionTypes.GET_PRE_ADMISSION_DETAIL_FAIL:
      return Object.assign({}, state, {
        preAdmissionDetail: null
      });
    /**
   * generate pre admission no reducer case function
   */
    case actions.ActionTypes.GENERATE_ADMISSION_NO:
      return Object.assign({}, state, {
        generateLoading: true,
        preAdmissionNo: null
      });
    case actions.ActionTypes.GENERATE_ADMISSION_NO_SUCCESS:
      return Object.assign({}, state, {
        generateLoading: false,
        preAdmissionNo: payload.data
      });
    case actions.ActionTypes.GENERATE_ADMISSION_NO_FAIL:
      return Object.assign({}, state, {
        generateLoading: false,
      });
    /**
     * pre admission change status reducer case function
     */
    case actions.ActionTypes.CHANGE_PRE_ADMISSION_STATUS:
      return Object.assign({}, state, {
        statusChange: null,
        statusChangeLoading: true,
        statusChangeLoaded: false
      });
    case actions.ActionTypes.CHANGE_PRE_ADMISSION_STATUS_SUCCESS:
      return Object.assign({}, state, {
        statusChange: payload,
        statusChangeLoading: false,
        statusChangeLoaded: true
      });
    case actions.ActionTypes.CHANGE_PRE_ADMISSION_STATUS_FAIL:
      return Object.assign({}, state, {
        statusChange: payload,
        statusChangeLoading: false,
        statusChangeLoaded: true
      });
    /**
     * pre admission delete reducer case function
     */
    case actions.ActionTypes.DELETE_PRE_ADMISSION:
      return Object.assign({}, state, {
        deletePreAdmission: null,
        deletePreAdmissionLoading: true,
        deletePreAdmissionLoaded: false
      });
    case actions.ActionTypes.DELETE_PRE_ADMISSION_SUCCESS:
      return Object.assign({}, state, {
        deletePreAdmission: payload,
        deletePreAdmissionLoading: false,
        deletePreAdmissionLoaded: true
      });
    case actions.ActionTypes.DELETE_PRE_ADMISSION_FAIL:
      return Object.assign({}, state, {
        deletePreAdmission: payload,
        deletePreAdmissionLoading: false,
        deletePreAdmissionLoaded: true
      });
    /**
     * pre admission section allotment
     */
    case actions.ActionTypes.ALLOT_SECTION:
      return Object.assign({}, state, {
        sectionAllotResult: null,
        sectionAllotLoading: true,
        sectionAllotLoaded: false
      });
    case actions.ActionTypes.ALLOT_SECTION_SUCCESS:
      return Object.assign({}, state, {
        sectionAllotResult: payload,
        sectionAllotLoading: false,
        sectionAllotLoaded: true
      });
    case actions.ActionTypes.ALLOT_SECTION_FAIL:
      return Object.assign({}, state, {
        sectionAllotResult: payload,
        sectionAllotLoading: false,
        sectionAllotLoaded: true
      });

    /**
   * pre admission section allotment
   */
    case actions.ActionTypes.IMPORT_ADMISSION:
      return Object.assign({}, state, {
        importAdmissionResult: null,
        importAdmissionLoading: true,
        importAdmissionLoaded: false
      });
    case actions.ActionTypes.IMPORT_ADMISSION_SUCCESS:
      return Object.assign({}, state, {
        importAdmissionResult: payload,
        importAdmissionLoading: false,
        importAdmissionLoaded: true
      });
    case actions.ActionTypes.IMPORT_ADMISSION_FAIL:
      return Object.assign({}, state, {
        importAdmissionResult: payload,
        importAdmissionLoading: false,
        importAdmissionLoaded: true
      });
    // select student for section allotment
    case actions.ActionTypes.SELECT_STUDENT:
      let tempList = [];
      if (state.selectedStudents) {
        tempList = state.selectedStudents;
      }
      tempList.push(payload);
      return Object.assign({}, state, {
        selectedStudents: tempList
      });
    // download student template
    case actions.ActionTypes.DOWNLOAD_STUDENT_TEMPLATE:
      return Object.assign({}, state, {
        downloadStudentTemplateLoading: true,
      });

    case actions.ActionTypes.DOWNLOAD_STUDENT_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        downloadStudentTemplate: payload.data,
        downloadStudentTemplateLoading: false,
      });

    case actions.ActionTypes.DOWNLOAD_STUDENT_TEMPLATE_FAIL:
      return Object.assign({}, state, {
        downloadStudentTemplateLoading: false,
      });
    // GROUP_CONFIGURE_DETAILS
    case actions.ActionTypes.GROUP_CONFIGURE_DETAILS:
      return Object.assign({}, state, {
      });

    case actions.ActionTypes.GROUP_CONFIGURE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        groupConfigureDetails: payload.data,
      });

    case actions.ActionTypes.GROUP_CONFIGURE_DETAILS_FAIL:
      return Object.assign({}, state, {
      });


      case actions.ActionTypes.GET_S3_PRE_SIGNED_URL:
        return Object.assign({}, state, {
          getS3PreSignedUrl: {},
          getS3PreSignedUrlLoading: true,
        });
  
      case actions.ActionTypes.GET_S3_PRE_SIGNED_URL_SUCCESS:
        return Object.assign({}, state, {
          getS3PreSignedUrl: payload,
          getS3PreSignedUrlLoading: false,
        });
  
      case actions.ActionTypes.GET_S3_PRE_SIGNED_URL_FAIL:
        return Object.assign({}, state, {
          getS3PreSignedUrl: payload,
          getS3PreSignedUrlLoading: false,
        });

    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const preAdmissionCreatinging = (state: PreAdmissionState) =>
  state.preAdmissionCreatinging;
export const preAdmissionCreated = (state: PreAdmissionState) =>
  state.preAdmissionCreated;
export const preAdmissionFailed = (state: PreAdmissionState) =>
  state.preAdmissionCreationFailed;
export const preAdmissionNo = (state: PreAdmissionState) =>
  state.preAdmissionNo;


export const preAdmissionLoading = (state: PreAdmissionState) =>
  state.preAdmissionLoading;
export const preAdmissionLoaded = (state: PreAdmissionState) =>
  state.preAdmissionLoaded;
export const preAdmissionList = (state: PreAdmissionState) =>
  state.preAdmissionList;
export const preAdmissionCount = (state: PreAdmissionState) =>
  state.preAdmissionCount;

export const statusChangeLoading = (state: PreAdmissionState) =>
  state.statusChangeLoading;
export const statusChange = (state: PreAdmissionState) =>
  state.statusChange;
export const statusChangeLoaded = (state: PreAdmissionState) =>
  state.statusChangeLoaded;
export const generateLoading = (state: PreAdmissionState) =>
  state.generateLoading;
export const preAdmissionDetail = (state: PreAdmissionState) =>
  state.preAdmissionDetail;

export const deletePreAdmission = (state: PreAdmissionState) =>
  state.deletePreAdmission;
export const deletePreAdmissionLoading = (state: PreAdmissionState) =>
  state.deletePreAdmissionLoading;
export const deletePreAdmissionLoaded = (state: PreAdmissionState) =>
  state.deletePreAdmissionLoaded;
export const selectedStudents = (state: PreAdmissionState) =>
  state.selectedStudents;

export const sectionAllotResult = (state: PreAdmissionState) =>
  state.sectionAllotResult;
export const sectionAllotLoading = (state: PreAdmissionState) =>
  state.sectionAllotLoading;
export const sectionAllotLoaded = (state: PreAdmissionState) =>
  state.sectionAllotLoaded;

export const importAdmissionResult = (state: PreAdmissionState) =>
  state.importAdmissionResult;
export const importAdmissionLoading = (state: PreAdmissionState) =>
  state.importAdmissionLoading;
export const importAdmissionLoaded = (state: PreAdmissionState) =>
  state.importAdmissionLoaded;


export const downloadStudentTemplate = (state: PreAdmissionState) => state.downloadStudentTemplate;
export const downloadStudentTemplateLoading = (state: PreAdmissionState) => state.downloadStudentTemplateLoading;

export const GenerateCashFreeOrder = (state: PreAdmissionState) => state.GenerateCashFreeOrder;
export const GenerateCashFreeOrderLoading = (state: PreAdmissionState) => state.GenerateCashFreeOrderLoading;
export const groupConfigureDetails = (state: PreAdmissionState) => state.groupConfigureDetails;


export const getS3PreSignedUrl = (state: PreAdmissionState) =>
  state.getS3PreSignedUrl;
export const getS3PreSignedUrlLoading = (state: PreAdmissionState) =>
  state.getS3PreSignedUrlLoading;