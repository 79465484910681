import {
  StudentManagementState,
  studentManagementRecord
} from './student-management.state';
import * as actions from '../actions/student-management.action';
import {get , uniqBy , filter} from 'lodash';

export const initialState: StudentManagementState = (new studentManagementRecord() as unknown) as StudentManagementState;

export function reducer(
  state = initialState,
  { type, payload }: any
): StudentManagementState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * add student
     */
    case actions.ActionTypes.ADD_STUDENT:
      return Object.assign({}, state, {
        studentAdded: null,
        addStudentLoading: true,
        addStudentLoaded: false,
        addStudentFailed: false
      });

    case actions.ActionTypes.ADD_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        studentAdded: payload,
        getStudentDetails: {},
        addStudentLoading: false,
        addStudentLoaded: true,
        addStudentFailed: false
      });

    case actions.ActionTypes.ADD_STUDENT_FAIL:
      return Object.assign({}, state, {
        addStudentLoading: false,
        addStudentLoaded: false,
        addStudentFailed: true
      });

    /**
     * student list
     */
    case actions.ActionTypes.STUDENT_LIST:
        return Object.assign({}, state, {
          uploadedDocument: [],
         // studentList: [],
          deletedStudentList: [],
          uploadedOriginalDocument: [],
          studentListLoading: true,
          studentListLoaded: false,
          studentListFailed: false
        });
    case actions.ActionTypes.STUDENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        studentList: payload.data,
        studentListLoading: false,
        studentListLoaded: true,
        studentListFailed: false
      });

    case actions.ActionTypes.STUDENT_LIST_FAIL:
      return Object.assign({}, state, {
        studentListLoading: false,
        studentListLoaded: false,
        studentListFailed: true
      });
    case actions.ActionTypes.RESET_STUDENT_VALUES:
      return Object.assign({}, state, {
        studentList: undefined
      });
    /**
     *  student standard( class they are studying ) list
     */
    case actions.ActionTypes.STANDARD_LIST:
      return Object.assign({}, state, {
        standardListLoading: true,
        standardListLoaded: false,
        standardListFailed: false
      });

    case actions.ActionTypes.STANDARD_LIST_SUCCESS:
      const tempStand = JSON.stringify(payload.data);
      if (get(payload, 'data.length') > 0) {
        const len = payload.data.length;
        payload.data[len] = {id: '', instituteId: '', classDisplayName: 'All' , className: 'All' , mStandardId: 0};
      }
      return Object.assign({}, state, {
        tempStandardList: JSON.parse(tempStand),
        standardList: payload.data,
        standardListLoading: false,
        standardListLoaded: true,
        standardListFailed: false
      });

    case actions.ActionTypes.STANDARD_LIST_FAIL:
      return Object.assign({}, state, {
        standardListLoading: false,
        standardListLoaded: false,
        standardListFailed: true
      });

    /**
     *  student  section list
     */
    case actions.ActionTypes.SECTION_LIST:
      return Object.assign({}, state, {
        sectionList: [],
        subjectSameForAllSection: null,
        sectionListLoading: true,
        sectionListLoaded: false,
        sectionListFailed: false
      });

    case actions.ActionTypes.SECTION_LIST_SUCCESS:
      return Object.assign({}, state, {
        subjectSameForAllSection: payload['subjectSameForAllSection'],
        sectionList: payload.data,
        sectionListLoading: false,
        sectionListLoaded: true,
        sectionListFailed: false
      });

    case actions.ActionTypes.SECTION_LIST_FAIL:
      return Object.assign({}, state, {
        sectionListLoading: false,
        sectionListLoaded: false,
        sectionListFailed: true
      });


      /**
     *  student  section sibling list
     */
    case actions.ActionTypes.SECTION_LIST_SIBLING:
      return Object.assign({}, state, {
        sectionSiblingList: [],
        sectionSiblingListLoading: true,
        sectionSiblingListLoaded: false,
        sectionSiblingListFailed: false
      });

    case actions.ActionTypes.SECTION_LIST_SIBLING_SUCCESS:
      return Object.assign({}, state, {
        sectionSiblingList: payload.data,
        sectionSiblingListLoading: false,
        sectionSiblingListLoaded: true,
        sectionSiblingListFailed: false
      });

    case actions.ActionTypes.SECTION_LIST_SIBLING_FAIL:
      return Object.assign({}, state, {
        sectionSiblingListLoading: false,
        sectionSiblingListLoaded: false,
        sectionSiblingListFailed: true
      });

    /**
     *  medium of student(In which language they are studying e.g tamil, english..)
     */
    case actions.ActionTypes.MEDIUM_OF_STUDENT:
      return Object.assign({}, state, {
        mediumOfStudentLoading: true,
        mediumOfStudentLoaded: false,
        mediumOfStudentFailed: false
      });

    case actions.ActionTypes.MEDIUM_OF_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        mediumList: payload.data,
        mediumOfStudentLoading: false,
        mediumOfStudentLoaded: true,
        mediumOfStudentFailed: false
      });

    case actions.ActionTypes.MEDIUM_OF_STUDENT_FAIL:
      return Object.assign({}, state, {
        mediumOfStudentLoading: false,
        mediumOfStudentLoaded: false,
        mediumOfStudentFailed: true
      });

    /**
     *  get the list of religion that student belongs to
     */
    case actions.ActionTypes.GET_RELIGION_LIST:
      return Object.assign({}, state, {
        religionListLoading: true,
        religionListLoaded: false,
        religionListFailed: false
      });

    case actions.ActionTypes.GET_RELIGION_LIST_SUCCESS:
      return Object.assign({}, state, {
        religionList: payload.data,
        religionListLoading: false,
        religionListLoaded: true,
        religionListFailed: false
      });

    case actions.ActionTypes.GET_RELIGION_LIST_FAIL:
      return Object.assign({}, state, {
        religionListLoading: false,
        religionListLoaded: false,
        religionListFailed: true
      });

    /**
     *  get the list of community that student belongs to
     */
    case actions.ActionTypes.GET_COMMUNITY_LIST:
      return Object.assign({}, state, {
        communityListLoading: true,
        communityListLoaded: false,
        communityListFailed: false
      });

    case actions.ActionTypes.GET_COMMUNITY_LIST_SUCCESS:
      return Object.assign({}, state, {
        communityList: payload.data,
        communityListLoading: false,
        communityListLoaded: true,
        communityListFailed: false
      });

    case actions.ActionTypes.GET_COMMUNITY_LIST_FAIL:
      return Object.assign({}, state, {
        communityListLoading: false,
        communityListLoaded: false,
        communityListFailed: true
      });

    /**
     *  get the blood group list
     */
    case actions.ActionTypes.BLOOD_GROUP_LIST:
      return Object.assign({}, state, {
        bloodGroupListLoading: true,
        bloodGroupListLoaded: false,
        bloodGroupListFailed: false
      });

    case actions.ActionTypes.BLOOD_GROUP_LIST_SUCCESS:
      return Object.assign({}, state, {
        bloodGroupList: payload.data,
        bloodGroupListLoading: false,
        bloodGroupListLoaded: true,
        bloodGroupListFailed: false
      });

    case actions.ActionTypes.BLOOD_GROUP_LIST_FAIL:
      return Object.assign({}, state, {
        bloodGroupListLoading: false,
        bloodGroupListLoaded: false,
        bloodGroupListFailed: true
      });

    /**
     *  get the blood group list
     */
    case actions.ActionTypes.FEES_PAYMENT_TYPE:
      return Object.assign({}, state, {
        feesPaymentTypeLoading: true,
        feesPaymentTypeLoaded: false,
        feesPaymentTypeFailed: false
      });

    case actions.ActionTypes.FEES_PAYMENT_TYPE_SUCCESS:
      return Object.assign({}, state, {
        feesPaymentType: payload.data,
        feesPaymentTypeLoading: false,
        feesPaymentTypeLoaded: true,
        feesPaymentTypeFailed: false
      });

    case actions.ActionTypes.FEES_PAYMENT_TYPE_FAIL:
      return Object.assign({}, state, {
        feesPaymentTypeLoading: false,
        feesPaymentTypeLoaded: false,
        feesPaymentTypeFailed: true
      });

    /**
     *  empty section list varibale
     */
    case actions.ActionTypes.EMPTY_SECTION_LIST:
      return Object.assign({}, state, {
        sectionList: undefined
      });

    /**
     *  get student details for update
     */
    case actions.ActionTypes.GET_STUDENT_DETAILS:
      return Object.assign({}, state, {
        getStudentDetails: null,
        getStudentDetailsLoading: true,
        getStudentDetailsLoaded: false,
        getStudentDetailsFailed: false
      });

    case actions.ActionTypes.GET_STUDENT_DETAILS_SUCCESS:
      const studentDetail = payload.data;
      let uploadedDoc = [];
      if (
        studentDetail.studentDocuments &&
        studentDetail.studentDocuments.length
      ) {
        uploadedDoc = studentDetail.studentDocuments;
      }
      // feesPaymentType
      return Object.assign({}, state, {
        uploadedDocument: uploadedDoc,
        uploadedOriginalDocument: uploadedDoc,
        getStudentDetails: studentDetail,
        getStudentDetailsLoading: false,
        getStudentDetailsLoaded: true,
        getStudentDetailsFailed: false
      });

    case actions.ActionTypes.GET_STUDENT_DETAILS_FAIL:
      return Object.assign({}, state, {
        getStudentDetailsLoading: false,
        getStudentDetailsLoaded: false,
        getStudentDetailsFailed: true
      });
    /**
     *  get student details for update
     */
    case actions.ActionTypes.GET_SIBLING_DETAIL:
      return Object.assign({}, state, {
        getSiblingDetails: null,
        getSiblingDetailsLoading: true,
        getSiblingDetailsLoaded: false,
        getSiblingDetailsFailed: false
      });

    case actions.ActionTypes.GET_SIBLING_DETAIL_SUCCESS:
      const siblingDetail = payload.data;
      return Object.assign({}, state, {
        getSiblingDetails: siblingDetail,
        getSiblingDetailsLoading: false,
        getSiblingDetailsLoaded: true,
        getSiblingDetailsFailed: false
      });

    case actions.ActionTypes.GET_SIBLING_DETAIL_FAIL:
      return Object.assign({}, state, {
        getSiblingDetailsLoading: false,
        getSiblingDetailsLoaded: false,
        getSiblingDetailsFailed: true
      });
    /**
     *  get student count
     */
    case actions.ActionTypes.GET_STUDENT_COUNT:
      return Object.assign({}, state, {
        // getStudentCount: [],
        getStudentCountLoading: true,
        getStudentCountLoaded: false,
        getStudentCountFailed: false
      });

    case actions.ActionTypes.GET_STUDENT_COUNT_SUCCESS:
      return Object.assign({}, state, {
        getStudentCount: payload.data,
        getStudentCountLoading: false,
        getStudentCountLoaded: true,
        getStudentCountFailed: false
      });

    case actions.ActionTypes.GET_STUDENT_COUNT_FAIL:
      return Object.assign({}, state, {
        getStudentCountLoading: false,
        getStudentCountLoaded: false,
        getStudentCountFailed: true
      });

    /**
     *  delete student
     */
    case actions.ActionTypes.DELETE_STUDENT:
      return Object.assign({}, state, {
        deleteStudentLoading: true,
        deleteStudenLoaded: false,
        deleteStudenFailed: false
      });

    case actions.ActionTypes.DELETE_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        deleteStudent: payload,
        deleteStudentLoading: false,
        deleteStudenLoaded: true,
        deleteStudenFailed: false
      });

    case actions.ActionTypes.DELETE_STUDENT_FAIL:
      return Object.assign({}, state, {
        deleteStudentLoading: false,
        deleteStudenLoaded: false,
        deleteStudenFailed: true
      });

    /**
     *  upload Document
     */
    case actions.ActionTypes.UPLOAD_DOCUMENT:
      return Object.assign({}, state, {
        uploadDocumentLoading: true,
        uploadDocumentLoaded: false,
        uploadDocumentFailed: false
      });

    case actions.ActionTypes.UPLOAD_DOCUMENT_SUCCESS:
      let temp = [];
      if (state.uploadedDocument) {
        temp = state.uploadedDocument;
      }
      temp.push(payload.data);

      return Object.assign({}, state, {
        uploadedDocument: temp,
        uploadedOriginalDocument: temp,
        uploadDocumentLoading: false,
        uploadDocumentLoaded: true,
        uploadDocumentFailed: false
      });

    case actions.ActionTypes.UPLOAD_DOCUMENT_FAIL:
      return Object.assign({}, state, {
        uploadDocumentLoading: false,
        uploadDocumentLoaded: false,
        uploadDocumentFailed: true
      });
    case actions.ActionTypes.REMOVE_DOCUMENT:
      let currentDocs = state.uploadedDocument;
      currentDocs = currentDocs.filter(val => {
        return val.documentName !== payload;
      });
      return Object.assign({}, state, {
        uploadedDocument: currentDocs
      }); 
      case actions.ActionTypes.RESET_DOCUMENT:
      
      return Object.assign({}, state, {
        uploadedDocument: state.uploadedOriginalDocument
      });

    /**
     *  get deleted student list
     */
    case actions.ActionTypes.DELETED_STUDENT_LIST:
      return Object.assign({}, state, {
        // studentList: [],
        deletedStudentListLoading: true,
        deletedStudentListLoaded: false,
        deletedStudentListFailed: false
      });

    case actions.ActionTypes.DELETED_STUDENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        deletedStudentList: payload.data,
        deletedStudentListLoading: false,
        deletedStudentListLoaded: true,
        deletedStudentListFailed: false
      });

    case actions.ActionTypes.DELETED_STUDENT_LIST_FAIL:
      return Object.assign({}, state, {
        deletedStudentListLoading: false,
        deletedStudentListLoaded: false,
        deletedStudentListFailed: true
      });

    /**
     *  revert student
     */
    case actions.ActionTypes.REVERT_STUDENT:
      return Object.assign({}, state, {
        revertStudentLoading: true,
        revertStudentLoaded: false,
        revertStudentFailed: false
      });

    case actions.ActionTypes.REVERT_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        revertStudent: payload,
        revertStudentLoading: false,
        revertStudentLoaded: true,
        revertStudentFailed: false
      });

    case actions.ActionTypes.REVERT_STUDENT_FAIL:
      return Object.assign({}, state, {
        revertStudentLoading: false,
        revertStudentLoaded: false,
        revertStudentFailed: true
      });

    // empty delete student variable in reducer
    case actions.ActionTypes.REVERT_STUDENT_FAIL:
      return Object.assign({}, state, {
        deleteStudent: undefined
      });

    /**
     * get route list
     */
    case actions.ActionTypes.GET_ROUTE_MASTER_LIST:
      return Object.assign({}, state, {
        routeMasterListLoading: true,
        routeMasterListLoaded: false,
        routeMasterListFailed: false
      });

    case actions.ActionTypes.GET_ROUTE_MASTER_LIST_SUCCESS:
      return Object.assign({}, state, {
        routeMasterList: payload.data,
        routeMasterListLoading: false,
        routeMasterListLoaded: true,
        routeMasterListFailed: false
      });

    case actions.ActionTypes.GET_ROUTE_MASTER_LIST_FAIL:
      return Object.assign({}, state, {
        routeMasterListLoading: false,
        routeMasterListLoaded: false,
        routeMasterListFailed: true
      });

    /**
     * get stoping point list
     */
    case actions.ActionTypes.STOPING_POINT_LIST:
      return Object.assign({}, state, {
        stopingPointListLoading: true,
        stopingPointListLoaded: false,
        stopingPointListFailed: false
      });

    case actions.ActionTypes.STOPING_POINT_LIST_SUCCESS:
      let routeList = [];
      if (payload.data) {
        routeList = payload.data.routeMasterDetails.routeStopsDetails;
        routeList = routeList.map(element => {
          element = element.busStopsDetails;
          return element;
        });
      }
      return Object.assign({}, state, {
        stopingPointList: routeList,
        stopingPointListLoading: false,
        stopingPointListLoaded: true,
        stopingPointListFailed: false
      });

    case actions.ActionTypes.STOPING_POINT_LIST_FAIL:
      return Object.assign({}, state, {
        stopingPointListLoading: false,
        stopingPointListLoaded: false,
        stopingPointListFailed: true
      });
    // get export student list
    case actions.ActionTypes.EXPORT_STUDENT_LIST:
      return Object.assign({}, state, {
        exportStudentLoading: false,
        exportStudentLoaded: false,
        exportStudentFailed: false
      });

    case actions.ActionTypes.EXPORT_STUDENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        exportStudent: payload,
        exportStudentLoading: false,
        exportStudentLoaded: false,
        exportStudentFailed: false
      });

    case actions.ActionTypes.EXPORT_STUDENT_LIST_FAIL:
      return Object.assign({}, state, {
        exportStudentLoading: false,
        exportStudentLoaded: false,
        exportStudentFailed: false
      });
    /**
     * get route master fee list case function
     */
    case actions.ActionTypes.GET_ROUTE_FEE_LIST:
      return Object.assign({}, state, {
        routeFeeListLoading: true
      });
    case actions.ActionTypes.GET_ROUTE_FEE_LIST_SUCCESS:
    
      return Object.assign({}, state, {
        routeFeeListLoading: false,
        routeFeeList: payload['date']
      });
    case actions.ActionTypes.GET_ROUTE_FEE_LIST_FAIL:
      return Object.assign({}, state, {
        routeFeeListLoading: false,
        routeFeeList: null
      });
    case actions.ActionTypes.GET_MONTH_LIST:
      return Object.assign({}, state, {});

    case actions.ActionTypes.GET_MONTH_LIST_SUCCESS:
      if (payload.data) {
        payload.data.forEach(element => {
          element.isSelected = false;
        });
      }

      return Object.assign({}, state, {
        monthList: payload.data
      });

    case actions.ActionTypes.GET_MONTH_LIST_FAIL:
      return Object.assign({}, state, {
        monthList: []
      });
      // promote student list
    case actions.ActionTypes.PROMOTE_STUDENT_LIST:
      return Object.assign({}, state, {
        promoteStudent: [],
        promoteStudentLoading: true,
        promoteStudentLoaded: false,
        promoteStudentFailed: false,
      });

    case actions.ActionTypes.PROMOTE_STUDENT_LIST_SUCCESS:
      const tempPromoteStudent = payload.data.map(_list => {
        _list['isChecked'] = false;
        return _list;
      });
      return Object.assign({}, state, {
        promoteStudent: tempPromoteStudent,
        promoteStudentLoading: false,
        promoteStudentLoaded: true,
        promoteStudentFailed: false,
      });

    case actions.ActionTypes.PROMOTE_STUDENT_LIST_FAIL:
      return Object.assign({}, state, {
        promoteStudentLoading: false,
        promoteStudentLoaded: false,
        promoteStudentFailed: true,
      });
      // academic year
    case actions.ActionTypes.ACADEMIC_YEAR_LIST:
      return Object.assign({}, state, {
        AcademicList: [],
        AcademicListLoading: true,
        AcademicListLoaded: false,
        AcademicListFailed: false,
      });

    case actions.ActionTypes.ACADEMIC_YEAR_LIST_SUCCESS:
      return Object.assign({}, state, {
        AcademicList: payload.data,
        AcademicListLoading: false,
        AcademicListLoaded: true,
        AcademicListFailed: false,
      });

    case actions.ActionTypes.ACADEMIC_YEAR_LIST_FAIL:
      return Object.assign({}, state, {
        AcademicListLoading: false,
        AcademicListLoaded: false,
        AcademicListFailed: true,
      });
      // clear all student
      case actions.ActionTypes.CLEAR_STUDENT:
      return Object.assign({}, state, {
        promoteStudent: [],
        sectionList: [],
        standardList: [],
        tempStandardList: [],
      });
      // select promote student
    case actions.ActionTypes.SELECT_PROMOTE_STUDENT_LIST:
      let tempSelectedStud = [];
      let temppromoteStudent = state.promoteStudent;
      if (state.selectedPromoteStudent) {
        tempSelectedStud = state.selectedPromoteStudent;
      }
      tempSelectedStud.push(payload);
      temppromoteStudent.forEach(list => {
        if (list.studentId === payload.studentId) {
          list['isChecked'] = true;
          return list;
        }
      });
      return Object.assign({}, state, {
        promoteStudent: temppromoteStudent,
        selectedPromoteStudent: tempSelectedStud,
        tempSelectedPromoteStudent: tempSelectedStud,
      });
      // remove promote student
    case actions.ActionTypes.REMOVE_PROMOTE_STUDENT_LIST:
      let removepromoteStudent = state.promoteStudent;
      let TempRemoveStudent =  state.selectedPromoteStudent;
      TempRemoveStudent = TempRemoveStudent.filter(data => data.studentId !== payload.studentId);
      removepromoteStudent.forEach(list => {
        if (list.studentId === payload.studentId) {
          list['isChecked'] = false;
          return list;
        }
      });
      return Object.assign({}, state, {
        promoteStudent: removepromoteStudent,
        selectedPromoteStudent: TempRemoveStudent,
        tempSelectedPromoteStudent: TempRemoveStudent,
      });
    case actions.ActionTypes.SELECT_ALL_STUDENT_LIST:
      let allPromoteStudentList = state.promoteStudent;
      let previousSAllPromotedStudent = [];
      let SelectedPromotedStudent = [];
      if (state.promoteStudent) {
        previousSAllPromotedStudent = get(state, 'selectedPromoteStudent') ? get(state, 'selectedPromoteStudent') : [];
        allPromoteStudentList = allPromoteStudentList.map(_data => {
          _data['isChecked'] = true;
          previousSAllPromotedStudent.push(_data);
          SelectedPromotedStudent = uniqBy(previousSAllPromotedStudent, 'studentId');
          return _data;
        });
      }
      return Object.assign({}, state, {
        promoteStudent: allPromoteStudentList,
        selectedPromoteStudent: SelectedPromotedStudent,
        tempSelectedPromoteStudent: SelectedPromotedStudent,
      });
    case actions.ActionTypes.CLEAR_ALL_STUDENT_LIST:
      let tempClearAllPromoteStudent = state.promoteStudent;
      if (state.promoteStudent) {
        tempClearAllPromoteStudent = tempClearAllPromoteStudent.map(_data => {
          _data['isChecked'] = false;
          return _data;
        });
      }
      return Object.assign({}, state, {
        promoteStudent: tempClearAllPromoteStudent,
        selectedPromoteStudent: [],
        tempSelectedPromoteStudent: [],
      });
    case actions.ActionTypes.CREATE_PROMOTE_STUDENT:
      return Object.assign({}, state, {
        createPromoteStudentLoading: true,
        createPromoteStudentLoaded: false,
        createPromoteStudentFailed: false,
      });

    case actions.ActionTypes.CREATE_PROMOTE_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        createPromoteStudent: payload,
        createPromoteStudentLoading: false,
        createPromoteStudentLoaded: true,
        createPromoteStudentFailed: false,
      });

    case actions.ActionTypes.CREATE_PROMOTE_STUDENT_FAIL:
      return Object.assign({}, state, {
        createPromoteStudentLoading: false,
        createPromoteStudentLoaded: false,
        createPromoteStudentFailed: true,
      });
      // search student
    case actions.ActionTypes.SEARCH_PROMOTE_STUDENT:
      let tempStudentSearch = state.tempSelectedPromoteStudent;
      const searchStudent = payload.toLowerCase();
      tempStudentSearch = filter(tempStudentSearch, (item) => {
        return item.studentName.toLowerCase().includes(searchStudent);
      });
      return Object.assign({}, state, {
        selectedPromoteStudent: tempStudentSearch,
      });
      // student message report
    case actions.ActionTypes.STUDENT_MESSAGE_DETAILS:
      return Object.assign({}, state, {
        studentMessageDetailsLoading: true,
      });

    case actions.ActionTypes.STUDENT_MESSAGE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        studentMessageDetails: payload.data,
        studentMessageDetailsLoading: false,
      });

    case actions.ActionTypes.STUDENT_MESSAGE_DETAILS_FAIL:
      return Object.assign({}, state, {
        studentMessageDetailsLoading: false,
      });
      // student message file
    case actions.ActionTypes.STUDENT_MESSAGE_FILE:
      return Object.assign({}, state, {
        studentMessageFile: [],
        studentMessageFileLoading: true,
      });

    case actions.ActionTypes.STUDENT_MESSAGE_FILE_SUCCESS:
      return Object.assign({}, state, {
        studentMessageFile: payload.data,
        studentMessageFileLoading: false,
      });

    case actions.ActionTypes.STUDENT_MESSAGE_FILE_FAIL:
      return Object.assign({}, state, {
        studentMessageFileLoading: false,
      });
      // import invalid staff
    case actions.ActionTypes.IMPORT_INVALID_STUDENT:
      return Object.assign({}, state, {
        importInvalidStudent: {},
        importInvalidStudentLoading: true,
      });

    case actions.ActionTypes.IMPORT_INVALID_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        importInvalidStudent: payload,
        importInvalidStudentLoading: false,
      });

    case actions.ActionTypes.IMPORT_INVALID_STUDENT_FAIL:
      return Object.assign({}, state, {
        importInvalidStudent: {},
        importInvalidStudentLoading: false,
      });

      // id card generate
    case actions.ActionTypes.ID_CARD_GENERATE:
    return Object.assign({}, state, {
      idCardGenerate: payload,
      idCardGenerateLoading: true,
      idCardGenerateLoaded: false,
      idCardGenerateFailed: false,
    });

  case actions.ActionTypes.IMPORT_INVALID_STUDENT_SUCCESS:
    return Object.assign({}, state, {
      idCardGenerateLoading: false,
      idCardGenerateLoaded: true,
      idCardGenerateFailed: false,
    });

  case actions.ActionTypes.ID_CARD_GENERATE_FAIL:
    return Object.assign({}, state, {
      idCardGenerateLoading: false,
      idCardGenerateLoaded: false,
      idCardGenerateFailed: true,
    });
    case actions.ActionTypes.CLEAR_ALL_SECTION:
    return Object.assign({}, state, {
      sectionList: [],

    });

    case actions.ActionTypes.CLEAR_STUDENT_LIST:
    return Object.assign({}, state, {
      studentList: [],
      getStudentCount: [],
      deletedStudentList: [],

    });
    // STUDENT VOICE REPORT
    case actions.ActionTypes.STUDENT_VOICE_REPORT:
    return Object.assign({}, state, {
      studentVoiceReportLoading: true,
    });

  case actions.ActionTypes.STUDENT_VOICE_REPORT_SUCCESS:
    return Object.assign({}, state, {
      studentVoiceReport: payload.data,
      studentVoiceReportLoading: false,
    });

  case actions.ActionTypes.STUDENT_VOICE_REPORT_FAIL:
    return Object.assign({}, state, {
      studentVoiceReportLoading: false,
    });
    case actions.ActionTypes.SUBJECT_LIST:
      return Object.assign({}, state, {
        subjectListLoading: true,
        subjectListLoaded: false,
        subjectListFailed: false
      });

    case actions.ActionTypes.SUBJECT_LIST_SUCCESS:
      return Object.assign({}, state, {
        subjectList: payload.data,
        subjectListLoading: false,
        subjectListLoaded: true,
        subjectListFailed: false
      });

    case actions.ActionTypes.SUBJECT_LIST_FAIL:
      return Object.assign({}, state, {
        subjectListLoading: false,
        subjectListLoaded: false,
        subjectListFailed: true
      });

    case actions.ActionTypes.GET_EXAMNAMES:
      return Object.assign({}, state, {
        GetExamNamesLoading: true,
        GetExamNamesLoaded: false,
        GetExamNamesFailed: false
      });

    case actions.ActionTypes.GET_EXAMNAMES_SUCCESS:
      return Object.assign({}, state, {
        GetExamNames: payload.data,
        GetExamNamesLoading: false,
        GetExamNamesLoaded: true,
        GetExamNamesFailed: false
      });

    case actions.ActionTypes.GET_EXAMNAMES_FAIL:
      return Object.assign({}, state, {
        GetExamNamesLoading: false,
        GetExamNamesLoaded: false,
        GetExamNamesFailed: true
      });

    case actions.ActionTypes.STDSEC_PROGRESS:
      return Object.assign({}, state, {
        GetStdSecProgressLoading: true,
        GetStdSecProgressLoaded: false,
        GetStdSecProgressFailed: false
      });

    case actions.ActionTypes.STDSEC_PROGRESS_SUCCESS:
      return Object.assign({}, state, {
        GetStdSecProgress: payload.data,
        GetStdSecProgressLoading: false,
        GetStdSecProgressLoaded: true,
        GetStdSecProgressFailed: false
      });

    case actions.ActionTypes.STDSEC_PROGRESS_FAIL:
      return Object.assign({}, state, {
        GetStdSecProgressLoading: false,
        GetStdSecProgressLoaded: false,
        GetStdSecProgressFailed: true
      });

    case actions.ActionTypes.STUDENT_LIST_PROGRESS:
      return Object.assign({}, state, {
        StudentListProgressLoading: true,
        StudentListProgressLoaded: false,
        StudentListProgressFailed: false
      });

    case actions.ActionTypes.STUDENT_LIST_PROGRESS_SUCCESS:
      return Object.assign({}, state, {
        StudentListProgress: payload.data.dataResult,
        StudentListProgressLoading: false,
        StudentListProgressLoaded: true,
        StudentListProgressFailed: false
      });

    case actions.ActionTypes.STUDENT_LIST_PROGRESS_FAIL:
      return Object.assign({}, state, {
        StudentListProgressLoading: false,
        StudentListProgressLoaded: false,
        StudentListProgressFailed: true
      });

    case actions.ActionTypes.EXAM_RESULT_ANALYSIS:
      return Object.assign({}, state, {
        examResultAnalysisLoading: true,
        examResultAnalysisLoaded: false,
        examResultAnalysisFailed: false
      });

    case actions.ActionTypes.EXAM_RESULT_ANALYSIS_SUCCESS:
      return Object.assign({}, state, {
        examResultAnalysis: payload.data,
        examResultAnalysisLoading: false,
        examResultAnalysisLoaded: true,
        examResultAnalysisFailed: false
      });

    case actions.ActionTypes.EXAM_RESULT_ANALYSIS_FAIL:
      return Object.assign({}, state, {
        examResultAnalysisLoading: false,
        examResultAnalysisLoaded: false,
        examResultAnalysisFailed: true
      });

    case actions.ActionTypes.PROGRESS_CARD:
      return Object.assign({}, state, {
        progressCardLoading: true,
        progressCardLoaded: false,
        progressCardFailed: false
      });

    case actions.ActionTypes.PROGRESS_CARD_SUCCESS:
      return Object.assign({}, state, {
        progressCard: payload.data,
        progressCardLoading: false,
        progressCardLoaded: true,
        progressCardFailed: false
      });

    case actions.ActionTypes.PROGRESS_CARD_FAIL:
      return Object.assign({}, state, {
        progressCardLoading: false,
        progressCardLoaded: false,
        progressCardFailed: true
      });

      //studyCertificate
      case actions.ActionTypes.STUDY_CERTIFICATE:
        return Object.assign({}, state, {
          studyCertificateLoading: true,
          studyCertificateLoaded: false,
          studyCertificateFailed: false
        });
  
      case actions.ActionTypes.STUDY_CERTIFICATE_SUCCESS:
        return Object.assign({}, state, {
          studyCertificate: payload.data,
          studyCertificateLoading: false,
          studyCertificateLoaded: true,
          studyCertificateFailed: false
        });
  
      case actions.ActionTypes.STUDY_CERTIFICATE_FAIL:
        return Object.assign({}, state, {
          studyCertificateLoading: false,
          studyCertificateLoaded: false,
          studyCertificateFailed: true
        });
  

             // Country LIST

             case actions.ActionTypes.COUNTRY_LIST: {
              return Object.assign({}, state, {
                  countryListLoading: true,
                  countryListLoaded: false,
                  countryListFailed: false,
              });
          }
  
          case actions.ActionTypes.COUNTRY_LIST_SUCCESS: {
              return Object.assign({}, state, {
                  countryList: payload.data,
                  countryListLoading: false,
                  countryListLoaded: true,
                  countryListFailed: false,
              });
          }
  
  
          case actions.ActionTypes.COUNTRY_LIST_FAIL: {
              return Object.assign({}, state, {
                  countryListLoading: false,
                  countryListLoaded: false,
                  countryListFailed: true,
              });
          }
  
          // Region LIST
  
          case actions.ActionTypes.REGION_LIST: {
              return Object.assign({}, state, {
                  regionListLoading: true,
                  regionListLoaded: false,
                  regionListFailed: false,
              });
          }
  
          case actions.ActionTypes.REGION_LIST_SUCCESS: {
              return Object.assign({}, state, {
                  regionList: payload.data,
                  regionListLoading: true,
                  regionListLoaded: true,
                  regionListFailed: false,
              });
          }
  
  
          case actions.ActionTypes.REGION_LIST_FAIL: {
              return Object.assign({}, state, {
                  regionListLoading: true,
                  regionListLoaded: false,
                  regionListFailed: true,
              });
          }
  
          // City LIST
  
          case actions.ActionTypes.CITY_LIST: {
              return Object.assign({}, state, {
                  cityListLoading: true,
                  cityListLoaded: false,
                  cityListFailed: false,
              });
          }
  
          case actions.ActionTypes.CITY_LIST_SUCCESS: {
              return Object.assign({}, state, {
                  cityList: payload.data,
                  cityListLoading: false,
                  cityListLoaded: true,
                  cityListFailed: false,
              });
          }
  
  
          case actions.ActionTypes.CITY_LIST_FAIL: {
              return Object.assign({}, state, {
                  cityListLoading: false,
                  cityListLoaded: false,
                  cityListFailed: true,
              });
          }


            /**
     *  student  section list
     */
    case actions.ActionTypes.ALLOCATED_STANDARD_LIST:
      return Object.assign({}, state, {
        allocatedStandardList: [],
        allocatedStandardListLoading: true,
        allocatedStandardListLoaded: false,
        allocatedStandardListFailed: false
      });

    case actions.ActionTypes.ALLOCATED_STANDARD_LIST_SUCCESS:
      return Object.assign({}, state, {
        allocatedStandardList: payload.data,
        allocatedStandardListLoading: false,
        allocatedStandardListLoaded: true,
        allocatedStandardListFailed: false
      });

    case actions.ActionTypes.ALLOCATED_STANDARD_LIST_FAIL:
      return Object.assign({}, state, {
        allocatedStandardListLoading: false,
        allocatedStandardListLoaded: false,
        allocatedStandardListFailed: true
      });


    default: {
      return state;
    }
  }
}

/**
 * export values
 */
export const addStudentLoading = (state: StudentManagementState) =>
  state.addStudentLoading;
export const addStudentLoaded = (state: StudentManagementState) =>
  state.addStudentLoaded;
export const addStudentFailed = (state: StudentManagementState) =>
  state.addStudentFailed;

export const studentList = (state: StudentManagementState) => state.studentList;
export const studentListLoading = (state: StudentManagementState) =>
  state.studentListLoading;
export const studentListLoaded = (state: StudentManagementState) =>
  state.studentListLoaded;
export const studentListFailed = (state: StudentManagementState) =>
  state.studentListFailed;

export const invalidStudentListLoading = (state: StudentManagementState) =>
  state.invalidStudentListLoading;
export const invalidStudentListLoaded = (state: StudentManagementState) =>
  state.invalidStudentListLoaded;
export const invalidStudentListFailed = (state: StudentManagementState) =>
  state.invalidStudentListFailed;

export const standardList = (state: StudentManagementState) =>
  state.standardList;
export const standardListLoading = (state: StudentManagementState) =>
  state.standardListLoading;
export const standardListLoaded = (state: StudentManagementState) =>
  state.standardListLoaded;
export const standardListFailed = (state: StudentManagementState) =>
  state.standardListFailed;

export const sectionList = (state: StudentManagementState) => state.sectionList;
export const sectionListLoading = (state: StudentManagementState) =>
  state.sectionListLoading;
export const sectionListLoaded = (state: StudentManagementState) =>
  state.sectionListLoaded;
export const sectionListFailed = (state: StudentManagementState) =>
  state.sectionListFailed;

export const sectionSiblingList = (state: StudentManagementState) => state.sectionSiblingList;
export const sectionSiblingListLoading = (state: StudentManagementState) =>
  state.sectionSiblingListLoading;
export const sectionSiblingListLoaded = (state: StudentManagementState) =>
  state.sectionSiblingListLoaded;
export const sectionSiblingListFailed = (state: StudentManagementState) =>
  state.sectionSiblingListFailed;

export const mediumList = (state: StudentManagementState) => state.mediumList;
export const mediumOfStudentLoading = (state: StudentManagementState) =>
  state.mediumOfStudentLoading;
export const mediumOfStudentLoaded = (state: StudentManagementState) =>
  state.mediumOfStudentLoaded;
export const mediumOfStudentFailed = (state: StudentManagementState) =>
  state.mediumOfStudentFailed;

export const religionList = (state: StudentManagementState) =>
  state.religionList;
export const religionListLoading = (state: StudentManagementState) =>
  state.religionListLoading;
export const religionListLoaded = (state: StudentManagementState) =>
  state.religionListLoaded;
export const religionListFailed = (state: StudentManagementState) =>
  state.religionListFailed;

export const communityList = (state: StudentManagementState) =>
  state.communityList;
export const communityListLoading = (state: StudentManagementState) =>
  state.communityListLoading;
export const communityListLoaded = (state: StudentManagementState) =>
  state.communityListLoaded;
export const communityListFailed = (state: StudentManagementState) =>
  state.communityListFailed;

export const bloodGroupList = (state: StudentManagementState) =>
  state.bloodGroupList;
export const bloodGroupListLoading = (state: StudentManagementState) =>
  state.bloodGroupListLoading;
export const bloodGroupListLoaded = (state: StudentManagementState) =>
  state.bloodGroupListLoaded;
export const bloodGroupListFailed = (state: StudentManagementState) =>
  state.bloodGroupListFailed;

export const feesPaymentType = (state: StudentManagementState) =>
  state.feesPaymentType;
export const feesPaymentTypeLoading = (state: StudentManagementState) =>
  state.feesPaymentTypeLoading;
export const feesPaymentTypeLoaded = (state: StudentManagementState) =>
  state.feesPaymentTypeLoaded;
export const feesPaymentTypeFailed = (state: StudentManagementState) =>
  state.feesPaymentTypeFailed;

export const getStudentDetails = (state: StudentManagementState) =>
  state.getStudentDetails;
export const getStudentDetailsLoading = (state: StudentManagementState) =>
  state.getStudentDetailsLoading;
export const getStudentDetailsLoaded = (state: StudentManagementState) =>
  state.getStudentDetailsLoaded;
export const getStudentDetailsFailed = (state: StudentManagementState) =>
  state.getStudentDetailsFailed;

export const getStudentCount = (state: StudentManagementState) =>
  state.getStudentCount;
export const getStudentCountLoading = (state: StudentManagementState) =>
  state.getStudentCountLoading;
export const getStudentCountLoaded = (state: StudentManagementState) =>
  state.getStudentCountLoaded;
export const getStudentCountFailed = (state: StudentManagementState) =>
  state.getStudentCountFailed;

export const deleteStudent = (state: StudentManagementState) =>
  state.deleteStudent;
export const deleteStudentLoading = (state: StudentManagementState) =>
  state.deleteStudentLoading;
export const deleteStudentLoaded = (state: StudentManagementState) =>
  state.deleteStudentLoaded;
export const deleteStudentFailed = (state: StudentManagementState) =>
  state.deleteStudentFailed;

export const uploadedDocument = (state: StudentManagementState) =>
  state.uploadedDocument;
export const uploadDocumentLoading = (state: StudentManagementState) =>
  state.uploadDocumentLoading;
export const uploadDocumentLoaded = (state: StudentManagementState) =>
  state.uploadDocumentLoaded;
export const uploadDocumentFailed = (state: StudentManagementState) =>
  state.uploadDocumentFailed;

export const deletedStudentList = (state: StudentManagementState) =>
  state.deletedStudentList;
export const deletedStudentListLoading = (state: StudentManagementState) =>
  state.deletedStudentListLoading;
export const deletedStudentListLoaded = (state: StudentManagementState) =>
  state.deletedStudentListLoaded;
export const deletedStudentListFailed = (state: StudentManagementState) =>
  state.deletedStudentListFailed;

export const revertStudent = (state: StudentManagementState) =>
  state.revertStudent;
export const revertStudentLoading = (state: StudentManagementState) =>
  state.revertStudentLoading;
export const revertStudentLoaded = (state: StudentManagementState) =>
  state.revertStudentLoaded;
export const revertStudentFailed = (state: StudentManagementState) =>
  state.revertStudentFailed;
export const studentAdded = (state: StudentManagementState) =>
  state.studentAdded;

export const getSiblingDetails = (state: StudentManagementState) =>
  state.getSiblingDetails;

export const routeMasterList = (state: StudentManagementState) =>
  state.routeMasterList;
export const routeMasterListLoading = (state: StudentManagementState) =>
  state.routeMasterListLoading;
export const routeMasterListLoaded = (state: StudentManagementState) =>
  state.routeMasterListLoaded;
export const routeMasterListFailed = (state: StudentManagementState) =>
  state.routeMasterListFailed;

export const stopingPointList = (state: StudentManagementState) =>
  state.stopingPointList;
export const stopingPointListLoading = (state: StudentManagementState) =>
  state.stopingPointListLoading;
export const stopingPointListLoaded = (state: StudentManagementState) =>
  state.stopingPointListLoaded;
export const stopingPointListFailed = (state: StudentManagementState) =>
  state.stopingPointListFailed;

export const exportStudent = (state: StudentManagementState) =>
  state.exportStudent;
export const exportStudentLoading = (state: StudentManagementState) =>
  state.exportStudentLoading;
export const exportStudentLoaded = (state: StudentManagementState) =>
  state.exportStudentLoaded;
export const exportStudentFailed = (state: StudentManagementState) =>
  state.exportStudentFailed;

export const routeFeeList = (state: StudentManagementState) =>
  state.routeFeeList;
export const routeFeeListLoading = (state: StudentManagementState) =>
  state.routeFeeListLoading;
export const getMonthList = (state: StudentManagementState) => state.monthList;

export const AcademicList = (state: StudentManagementState) =>
    state.AcademicList;
export const AcademicListLoading = (state: StudentManagementState) =>
    state.AcademicListLoading;

export const promoteStudent = (state: StudentManagementState) =>
    state.promoteStudent;
export const promoteStudentLoading = (state: StudentManagementState) =>
    state.promoteStudentLoading;
export const selectedPromoteStudent = (state: StudentManagementState) =>
    state.selectedPromoteStudent;

export const createPromoteStudent = (state: StudentManagementState) =>
    state.createPromoteStudent;

export const createPromoteStudentLoading = (state: StudentManagementState) =>
    state.createPromoteStudentLoading;

export const studentMessageDetails = (state: StudentManagementState) =>
    state.studentMessageDetails;
export const studentMessageDetailsLoading = (state: StudentManagementState) =>
    state.studentMessageDetailsLoading;

export const studentMessageFile = (state: StudentManagementState) =>
    state.studentMessageFile;
export const studentMessageFileLoading = (state: StudentManagementState) =>
    state.studentMessageFileLoading;
export const importInvalidStudent = (state: StudentManagementState) =>
    state.importInvalidStudent;
export const importInvalidStudentLoading = (state: StudentManagementState) =>
    state.importInvalidStudentLoading;


export const idCardGenerate = (state: StudentManagementState) =>
state.idCardGenerate;

export const tempStandardList = (state: StudentManagementState) => state.tempStandardList;

export const studentVoiceReportLoading = (state: StudentManagementState) => state.studentVoiceReportLoading;
export const studentVoiceReport = (state: StudentManagementState) => state.studentVoiceReport;
export const subjectList = (state: StudentManagementState) => state.subjectList;
export const subjectListLoading = (state: StudentManagementState) =>
  state.subjectListLoading;
export const subjectListLoaded = (state: StudentManagementState) =>
  state.subjectListLoaded;
export const subjectListFailed = (state: StudentManagementState) =>
  state.subjectListFailed;

export const GetExamNames = (state: StudentManagementState) =>
  state.GetExamNames;
export const GetExamNamesLoading = (state: StudentManagementState) =>
  state.GetExamNamesLoading;
export const GetExamNamesLoaded = (state: StudentManagementState) =>
  state.GetExamNamesLoaded;
export const GetExamNamesFailed = (state: StudentManagementState) =>
  state.GetExamNamesFailed;

export const GetStdSecProgress = (state: StudentManagementState) =>
  state.GetStdSecProgress;
export const GetStdSecProgressLoading = (state: StudentManagementState) =>
  state.GetStdSecProgressLoading;
export const GetStdSecProgressLoaded = (state: StudentManagementState) =>
  state.GetStdSecProgressLoaded;
export const GetStdSecProgressFailed = (state: StudentManagementState) =>
  state.GetStdSecProgressFailed;

export const StudentListProgress = (state: StudentManagementState) =>
  state.StudentListProgress;
export const StudentListProgressLoading = (state: StudentManagementState) =>
  state.StudentListProgressLoading;
export const StudentListProgressLoaded = (state: StudentManagementState) =>
  state.StudentListProgressLoaded;
export const StudentListProgressFailed = (state: StudentManagementState) =>
  state.StudentListProgressFailed;

export const examResultAnalysis = (state: StudentManagementState) =>
  state.examResultAnalysis;
export const examResultAnalysisLoading = (state: StudentManagementState) =>
  state.examResultAnalysisLoading;
export const examResultAnalysisLoaded = (state: StudentManagementState) =>
  state.examResultAnalysisLoaded;
export const examResultAnalysisFailed = (state: StudentManagementState) =>
  state.examResultAnalysisFailed;

export const progressCard = (state: StudentManagementState) =>
  state.progressCard;
export const progressCardLoading = (state: StudentManagementState) =>
  state.progressCardLoading;
export const progressCardLoaded = (state: StudentManagementState) =>
  state.progressCardLoaded;
export const progressCardFailed = (state: StudentManagementState) =>
  state.progressCardFailed;

export const subjectSameForAllSection = (state: StudentManagementState) => state.subjectSameForAllSection;

// country list
export const countryList = (state: StudentManagementState) => state.countryList;
export const countryListLoading = (state: StudentManagementState) => state.countryListLoading;
export const countryListLoaded = (state: StudentManagementState) => state.countryListLoaded;
export const countryListFailed = (state: StudentManagementState) => state.countryListFailed;
// region list

export const regionList = (state: StudentManagementState) => state.regionList;
export const regionListLoading = (state: StudentManagementState) => state.regionListLoading;
export const regionListLoaded = (state: StudentManagementState) => state.regionListLoaded;
export const regionListFailed = (state: StudentManagementState) => state.regionListFailed;
// city list

export const cityList = (state: StudentManagementState) => state.cityList;
export const cityListLoading = (state: StudentManagementState) => state.cityListLoading;
export const cityListLoaded = (state: StudentManagementState) => state.cityListLoaded;
export const cityListFailed = (state: StudentManagementState) => state.cityListFailed;


export const studyCertificate = (state: StudentManagementState) =>
  state.studyCertificate;
export const studyCertificateLoading = (state: StudentManagementState) =>
  state.studyCertificateLoading;
export const studyCertificateLoaded = (state: StudentManagementState) =>
  state.studyCertificateLoaded;
export const studyCertificateFailed = (state: StudentManagementState) =>
  state.studyCertificateFailed;
  //studyCertificate

export const allocatedStandardList = (state: StudentManagementState) =>
state.allocatedStandardList;
export const allocatedStandardListLoading = (state: StudentManagementState) =>
state.allocatedStandardListLoading;
export const allocatedStandardListLoaded = (state: StudentManagementState) =>
state.allocatedStandardListLoaded;
export const allocatedStandardListFailed = (state: StudentManagementState) =>
  state.allocatedStandardListFailed;